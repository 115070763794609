import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { format } from 'date-fns';
import Profile from '../Components/Profile';
import TemplateName from '../Components/TemplateName';
import ArtifactComponent from '../Components/ArtifactComponent';
import '../Assests/css/style.css';
import '../Assests/css/responsive.css';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import assetEmpty from '../Assests/images/asset-empty-feed.png';
import errorImg from '../Assests/images/error-img.svg';
import { useVirtual } from "./useVirtual";

const Home = () => {
    const scrollContainerRef = React.useRef(null)
    const { portfolioID } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [activeSectionId, setActiveSectionId] = useState('');
    const [posts, setAllPosts] = useState([]);
    const [feedArtifacts, setAllFeeds] = useState([]);
    const [sectionsData, setSections] = useState([]);
    const [selectedSectionData, setSelectedSectionData] = useState('');
    const [selectedSectionName, setSelectedSectionName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [sortedFeedArtifacts, setSortedFeedArtifacts] = useState([]);
    const [padding, setPadding] = React.useState(scrollContainerRef?.current?.offsetHeight ?? 0);


    const parentRef = React.useRef(null)

    //************GET FEEDS**************//
    const getFeedDataRequest = async () => {
        if (isLoading) return;
        setIsLoading(true);
        setTimeout(async () => {
            try {
            await fetch(`/api/portfolio/${portfolioID}`, {
                mode: 'cors',
                method: 'GET',
                headers: {
                    accept: 'application/json',
                },
            })
            .then(response => {
                if (!response.ok) {                    
                    setIsLoading(false);
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Parse JSON once
            })
            .then(data => {
                setAllPosts(data);
                setAllFeeds(data.feedArtifacts);
                setSections(data.sections);
                setIsLoading(false);                
                setSortedFeedArtifacts((sortedFeedArtifacts) => data.feedArtifacts.sort((a, b) => new Date(b.date) - new Date(a.date)));
            })
            .catch(error => {
                console.error('Error:', error);
                setIsLoading(false);
                navigate('/404');       
            })
            .finally(() => {
                setIsLoading(false);
            });
            } catch (error) {
                console.error('Error:', error);
                setIsLoading(false);
            }
        }, 500);
    }

    useEffect(() => {
        getFeedDataRequest()
    }, []);

    //***********SECTION CLICK MANAGE**********//
    const handleClick = (e, value) => {
        e.preventDefault();
        getFeedDataRequest() 
        document.body.classList.remove('mobile-menu-open');
        if (value !== '' || e.target.id === 'btn-section-name') {
            // SECTION SELECTED
            setActiveSectionId(value); // Set the active section ID
            const selectedData = feedArtifacts.filter(item => item.sectionId === value);
            const selectedName = sectionsData.find(item => item.id === value); // Use `find` instead of `filter`
            setSelectedSectionName(selectedName ? selectedName.name : ''); // Check if `selectedName` exists
            setSelectedSectionData(selectedData);
            // setSortedFeedArtifacts((sortedFeedArtifacts) => selectedData.sort((a, b) => new Date(b.date) - new Date(a.date)));
        } else {
            // HOME SELECTED
            setActiveSectionId(''); // Reset the active section ID for the home button
            setSelectedSectionData('');
        }
        parentRef.current.scrollTop = 0;
    };

    //***********VIRTUALIZATION**********//
    React.useEffect(() => {
        setPadding(scrollContainerRef?.current?.offsetHeight ?? 0);
      }, [scrollContainerRef?.current?.offsetHeight]);

    const rowVirtualizer = useVirtual({
        size: sortedFeedArtifacts.length,
        parentRef,
        useObserver: React.useCallback(() => ({ height: 900, width: 900 }), []),
        overscan: 4,
        paddingStart: padding
    });

    //**********SECTION VIRTUALIZATION*********//
    let sectionCount = 0;
    if (selectedSectionData && selectedSectionData.length > 0) {
        const sortedSectionFeedArtifacts = selectedSectionData.sort((a, b) => new Date(b.date) - new Date(a.date));
        sectionCount = sortedSectionFeedArtifacts.length;        
    }
    const rowSectionVirtualizer = useVirtual({
        size: sectionCount,
        parentRef,
        useObserver: React.useCallback(() => ({ height: 300, width: 300 }), []),
        overscan: 4
    });

    return (
        <>
            <div className="home-main">
                <TemplateName
                    text={posts?.portfolioName}
                    iconClass={'icon-archive'}
                    sectionText={!selectedSectionData ? t('home') : selectedSectionName}
                    isHome={!selectedSectionData ? 'true' : 'false'}
                />
                <div className="d-flex flex-wrap">
                    {/***** SIDEBAR *****/}
                    <div className="sidebar-main">
                        <div className="d-sm-none">
                            <div className="sidebar-template-name d-flex flex-wrap align-items-center">
                                <Link
                                    onClick={() => {
                                        document.body.classList.remove('mobile-menu-open');
                                    }}
                                    to={'/'.portfolioID}>
                                    <i className="icon icon-close"></i>
                                </Link>
                                <h4>{posts.portfolioName}</h4>
                            </div>
                        </div>
                        <ul>
                            <li className={` ${activeSectionId === '' ? 'active' : ''}`}>
                                <Button id="btn-home" className="d-flex align-items-center" onClick={(e) => handleClick(e, '')}>
                                    <div className="sideIcon"><i className="icon icon-archive"></i></div><p>{t('home')}</p><span className="menu-count">{feedArtifacts.length ? feedArtifacts.length : 0}</span>
                                </Button>
                            </li>
                            {sectionsData?.map((section, index) => {
                                // Filter the feedArtifacts based on the section.id
                                const filtered = feedArtifacts.filter((item) => item.sectionId === section.id);

                                return (
                                    <li key={section.id} className={` ${section.id === activeSectionId ? 'active' : ''}`}>
                                        <Button id="btn-section-name" className="d-flex align-items-center" onClick={(e) => handleClick(e, section.id)}>
                                            <div className="sideIcon">
                                                <i className="icon icon-folder"></i>
                                            </div>
                                            <p>{section.name}</p>
                                            {<span className="menu-count">{filtered.length}</span>}
                                        </Button>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    {/***** PROFILE *****/}
                    <div className="home-content" 
                        style={{
                            // height: '100%',
                            // width: '100%',
                            overflowY: 'auto',
                            contain: 'strict',
                        }} 
                        ref={parentRef}
                    >
                        {!selectedSectionData ? (
                            <Profile
                                title={posts.studentName}
                                grade={posts.studentGrade}
                                studentNumber={posts.studentNumber}
                                imageSrc={posts.displayImage?.sources[0].url}
                                description={posts.portfolioSummary} />
                        ) : (
                            <div className="profile-container">
                                <div className="profile-head d-flex flex-wrap align-items-center ">
                                    <h2 className="flex-grow-1 ">{selectedSectionName}</h2>
                                </div>
                            </div>
                        )}

                        <div className="posts-main">

                            {isLoading ? (
                                // Render skeleton or loading UI here for 2 seconds
                                <div className="posts skeleton-post">
                                    <div className="posts-profile-head d-flex flex-wrap align-items-center">
                                        <div className="posts-profile-img">
                                            <Skeleton
                                                style={{ backgroundColor: '#ccc', borderRadius: '50%' }} // Add background color style
                                            />
                                        </div>
                                        <div className="posts-profile-name flex-grow-1">
                                            <div className="mb-2">
                                                <Skeleton
                                                    width={250}
                                                    height={10}
                                                    style={{ backgroundColor: '#ccc' }} // Add background color style
                                                />
                                            </div>
                                            <Skeleton
                                                width={150}
                                                height={5}
                                                style={{ backgroundColor: '#ccc' }} // Add background color style
                                            />
                                        </div>
                                    </div>
                                    <div className="single-img-container">
                                        <div className="image-container-inner">
                                            <Skeleton
                                                width="100%"
                                                height="100%"
                                                style={{ backgroundColor: '#ccc' }} // Add background color style
                                            />
                                        </div>
                                    </div>
                                    <div className="post-content">
                                        <div className="mt-3 mb-2">
                                            <Skeleton
                                                width="50%"
                                                height={20}
                                                style={{ backgroundColor: '#ccc' }} // Add background color style
                                            />
                                        </div>
                                        <div className="mb-1">
                                            <Skeleton
                                                width="100%"
                                                height={8}
                                                style={{ backgroundColor: '#ccc' }} // Add background color style
                                            />
                                        </div>
                                        <div className="mb-1">
                                            <Skeleton
                                                width="100%"
                                                height={8}
                                                style={{ backgroundColor: '#ccc' }} // Add background color style
                                            />
                                        </div>
                                        <div className="mb-1">
                                            <Skeleton
                                                width="50%"
                                                height={8}
                                                style={{ backgroundColor: '#ccc' }} // Add background color style
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <Skeleton
                                                width={150}
                                                height={10}
                                                style={{ backgroundColor: '#ccc' }} // Add background color style
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {!selectedSectionData && sortedFeedArtifacts.length > 0 ? (
                                        //***** Render all content [HOME]*****//
                                        <div  style={{ position: "relative", height: `${rowVirtualizer.totalSize - padding}px`, width: "100%" }} >
                                            {
                                            rowVirtualizer.virtualItems?.map((virtualItem) => {
                                                const { index,start } = virtualItem;
                                                const artifact = sortedFeedArtifacts[index]; // Access the corresponding data item
                                                const parsedDate = new Date(artifact.date); // Parse the string into a Date object
                                                const formattedDate = format(parsedDate, t('date_format'));
                                                const isLastItem = index === feedArtifacts.length - 1;
                                                return (
                                                    <div 
                                                        key={virtualItem.key} 
                                                        start={start - padding} 
                                                        data-index={virtualItem.index} 
                                                        data-key={virtualItem.key}
                                                        ref={virtualItem.measureRef} 
                                                        className={virtualItem.index % 2 ? 'ListItemOdd' : 'ListItemEven'}
                                                        style={{ position: "absolute", top: 0, left: 0, width: "100%", 
                                                            transform: `translateY(${start}px)`,
                                                        }}
                                                    >
                                                        <ArtifactComponent
                                                            index={index}
                                                            profileImgUrl={artifact?.displayImage?.sources[0]?.url}
                                                            profileTitle={artifact?.ownerName}
                                                            profileDate={formattedDate}
                                                            attachments={artifact?.attachments}
                                                            showImages={true}
                                                            postTitle={artifact?.title}
                                                            postDescription={artifact?.body}
                                                            sectionId={virtualItem?.key}
                                                            feedArtifactsLength={sortedFeedArtifacts?.length}
                                                            feedArtifacts={sortedFeedArtifacts}
                                                            viewMore={true}
                                                            viewIcon={true}
                                                        />
                                                        {isLastItem && sortedFeedArtifacts.length > 3 &&
                                                            // Render content if more than 3 feeds
                                                            <div className="no-work">
                                                                <img src={errorImg} alt={t("You've reached the end")} />
                                                                <h4>{t("You've reached the end")}</h4>
                                                                <p>{t("That's all of the posts that we have for you")}</p>
                                                            </div>
                                                        }
                                                    </div>
                                                );
                                            })
                                            }
                                        </div>
                                    ) : (
                                        //***** Render content if selectedSectionData has items [SECTIONS]*****//
                                        <div>
                                            {selectedSectionData  &&  selectedSectionData.length > 0 ? (
                                                 <div style={{ position: "relative", height: `${rowSectionVirtualizer.totalSize}px`, width: "100%",}} > 
                                                    {
                                                    rowSectionVirtualizer.virtualItems?.map((virtualItem) => {
                                                        const { index,start } = virtualItem;
                                                        const artifact = selectedSectionData[index]
                                                        const parsedDate = new Date(artifact.date); // Parse the string into a Date object
                                                        const formattedDate = format(parsedDate, t('date_format'));
                                                        const isLastItem = index === selectedSectionData.length - 1;
                                                        return (
                                                            <div 
                                                            key={virtualItem.key} 
                                                            start={start} 
                                                            data-index={virtualItem.index} 
                                                            data-key={virtualItem.key}
                                                            ref={virtualItem.measureRef} 
                                                            className={virtualItem.index % 2 ? 'ListItemOdd' : 'ListItemEven'}
                                                            style={{ position: "absolute", top: 0, left: 0, width: "100%", 
                                                                transform: `translateY(${start}px)`,
                                                            }}
                                                            >

                                                                <ArtifactComponent
                                                                    profileImgUrl={artifact?.displayImage?.sources[0]?.url}
                                                                    profileTitle={artifact?.ownerName}
                                                                    profileDate={formattedDate}
                                                                    attachments={artifact?.attachments}
                                                                    showImages={true}
                                                                    postTitle={artifact?.title}
                                                                    postDescription={artifact?.body}
                                                                    sectionId={virtualItem?.key}
                                                                    feedArtifactsLength={selectedSectionData.length}
                                                                    feedArtifacts={selectedSectionData}
                                                                    viewMore={true}
                                                                    viewIcon={true}
                                                                />
                                                                {isLastItem && selectedSectionData.length > 3 &&
                                                                    // Render content if more than 3 feeds
                                                                    <div className="no-work">
                                                                        <img src={errorImg} alt={t("You've reached the end")} />
                                                                        <h4>{t("You've reached the end")}</h4>
                                                                        <p>{t("That's all of the posts that we have for you")}</p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            ) : (
                                                // Render content if selectedSectionData is empty
                                                <div className="no-work">
                                                    <img src={assetEmpty} alt={t('No work has been added yet')} />
                                                    <h4>{t('No work has been added yet')}</h4>
                                                    <p>{t('No Work SubTitle')}</p>
                                                </div>
                                            )}
                                    </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
